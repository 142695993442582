import React, { useState } from "react";
import {
	Avatar,
	Button,
	TextField,
	Paper,
	Box,
	Grid,
	Typography,
	CssBaseline,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import axios from "axios";
import ImageBG from "../assets/img/logo-vertical-grupo-altum-old.png";
function LoginForm({ onLogin }) {
	const [matricula, setMatricula] = useState("");
	const [senha, setSenha] = useState("");
	const [error, setError] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.post(
				// `https://api-chatbot.sagris.com.br/token`,
				process.env.NODE_ENV
					? "https://api-chatbot.sagris.com.br/token"
					: `http://localhost:8000/token`,
				new URLSearchParams({
					username: matricula,
					password: senha,
				}),
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);
			const token = response.data.access_token;
			onLogin(token);
		} catch (err) {
			setError("Matrícula ou senha incorretas");
		}
	};

	return (
		<Grid container component="main" sx={{ height: "100vh" }}>
			<CssBaseline />
			{/* Imagem de fundo */}
			<Grid
				item
				xs={false}
				sm={4}
				md={7}
				sx={{
					backgroundImage: `url(${ImageBG})`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "50%",
					backgroundPosition: "center",
				}}
			/>
			{/* Formulário de login */}
			<Grid
				item
				xs={12}
				sm={8}
				md={5}
				component={Paper}
				elevation={6}
				square
			>
				<Box
					sx={{
						my: 8,
						mx: 4,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Entrar
					</Typography>
					<Box
						component="form"
						noValidate
						onSubmit={handleSubmit}
						sx={{ mt: 1 }}
					>
						<TextField
							margin="normal"
							required
							fullWidth
							id="matricula"
							label="Matrícula"
							name="matricula"
							autoComplete="matricula"
							autoFocus
							value={matricula}
							onChange={(e) => setMatricula(e.target.value)}
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							name="senha"
							label="Senha"
							type="password"
							id="senha"
							autoComplete="current-password"
							value={senha}
							onChange={(e) => setSenha(e.target.value)}
						/>
						{error && (
							<Typography color="error" variant="body2">
								{error}
							</Typography>
						)}
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							Entrar
						</Button>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
}

export default LoginForm;
