import React, { useState } from "react";
import {
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Container,
	TextField,
	Button,
	List,
	ListItem,
	ListItemText,
	Box,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import axios from "axios";

function ChatWindow({ token, onLogout }) {
	const [mensagem, setMensagem] = useState("");
	const [historico, setHistorico] = useState([]);
	const [disabledButton, setDisabledButton] = useState(false);

	const api = axios.create({
		baseURL: process.env.NODE_ENV
			? "https://api-chatbot.sagris.com.br/"
			: `http://localhost:8000/`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	// Interceptar respostas com erro
	api.interceptors.response.use(
		(response) => response,
		(error) => {
			if (error.response && error.response.status === 401) {
				// Token inválido ou expirado
				onLogout();
			}
			return Promise.reject(error);
		}
	);

	const parseStringWithLink = (responseString) => {
		// Regex para detectar um link (URLs simples: http ou https)
		const urlRegex = /(https?:\/\/[^\s]+)/g;

		// Substituir a URL por um elemento <a>
		const parts = responseString.split(urlRegex);

		// Renderiza o texto com o link como um elemento <a>
		const jsxContent = parts.map((part, index) => {
			if (urlRegex.test(part)) {
				// Função para baixar o arquivo usando axios
				const handleDownload = async (e) => {
					e.preventDefault();
					try {
						let urlParse = part.replace("(", "").replace(")", "");
						const response = await api.get(urlParse, {
							responseType: "blob",
						});

						// Criar um URL Blob para o arquivo baixado
						const fileURL = window.URL.createObjectURL(
							new Blob([response.data])
						);
						// const fileName = part.split("urlParse").pop(); // Extrai o nome do arquivo da URL
						const fileName = "holerite.pdf";
						// Criar temporariamente um elemento <a> para acionar o download
						const a = document.createElement("a");
						a.href = fileURL;
						a.setAttribute("download", fileName);
						document.body.appendChild(a);
						a.click();
						document.body.removeChild(a);
					} catch (error) {
						console.error("Erro ao baixar o arquivo:", error);
					}
				};

				// Retorna o elemento <a> para a URL
				return (
					<a href="#" key={index} onClick={handleDownload}>
						[aqui]
					</a>
				);
			}
			return <span key={index}>{part}</span>;
		});

		return jsxContent;
	};

	const handleEnviar = async (e) => {
		e.preventDefault();
		if (!mensagem.trim()) return;

		const novaMensagem = { remetente: "Você", texto: mensagem };
		setHistorico([...historico, novaMensagem]);
		setMensagem("");
		setDisabledButton(true);
		try {
			const response = await api.post("chat", { mensagem });
			const respostaBot = {
				remetente: "Bot",
				texto: response.data.resposta,
			};
			setHistorico((hist) => [...hist, respostaBot]);
		} catch (err) {
			console.error(err);
		}
		setDisabledButton(false);
		setMensagem("");
	};

	return (
		<Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
			{/* Barra superior */}
			<AppBar position="static">
				<Toolbar>
					<Typography variant="h6" sx={{ flexGrow: 1 }}>
						Chatbot
					</Typography>
					<IconButton color="inherit" onClick={onLogout}>
						<LogoutIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			{/* Conteúdo do chat */}
			<Container sx={{ flexGrow: 1, overflowY: "auto", mt: 2 }}>
				<List>
					{historico.map((msg, index) => (
						<ListItem
							key={index}
							sx={{
								justifyContent:
									msg.remetente === "Você"
										? "flex-end"
										: "flex-start",
							}}
						>
							<ListItemText
								primary={parseStringWithLink(msg.texto)}
								secondary={msg.remetente}
								sx={{
									textAlign:
										msg.remetente === "Você"
											? "right"
											: "left",
									maxWidth: "70%",
								}}
							/>
						</ListItem>
					))}
				</List>
			</Container>
			{/* Campo de entrada de mensagem */}
			<Box
				component="form"
				onSubmit={handleEnviar}
				sx={{ display: "flex", p: 2 }}
			>
				<TextField
					fullWidth
					variant="outlined"
					placeholder="Digite sua mensagem"
					value={mensagem}
					onChange={(e) => setMensagem(e.target.value)}
				/>
				<Button
					type="submit"
					variant="contained"
					sx={{ ml: 2 }}
					disabled={disabledButton}
				>
					Enviar
				</Button>
			</Box>
		</Box>
	);
}

export default ChatWindow;
