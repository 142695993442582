import React, { useState, useEffect } from 'react';
import LoginForm from './components/LoginForm';
import ChatWindow from './components/ChatWindow';

function App() {
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Carrega o token do localStorage quando o componente é montado
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  useEffect(() => {
    // Atualiza o localStorage sempre que o token mudar
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
  }, [token]);

  const handleLogin = (token) => {
    setToken(token);
    localStorage.setItem('token', token);
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem('token');
  };

  return (
    <div>
      {token ? (
        <ChatWindow token={token} onLogout={handleLogout} />
      ) : (
        <LoginForm onLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;
